<!-- @format -->

<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text breadcrumb-text1">
              <h1 style="text-align: center" id="deptTitle"></h1>
              <!-- <div class="breadcrumb-bar">
                <ul class="breadcrumb">
                  <li>
                    <router-link to="/"> Fac </router-link>
                  </li>
                  <li > Department </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-form-area section-padding" v-show="showAbout">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12">
            <!-- <h4 class="contact-title"></h4> -->
            <div class="contact-text">
              <p
                v-for="about in About"
                :key="about.postID"
                style="background-color: rgb(52, 152, 219) !important"
              >
                <span class="c-icon">
                  <i class="fa fa-chevron-left"></i>
                </span>
                <span class="c-text">
                  <a
                    href="#"
                    aria-label="title"
                    @click="getAboutById(about.postID)"
                    >{{ about.postTitle }}</a
                  >
                </span>
              </p>

              <p
                v-for="leader in Leader"
                :key="leader.postId"
                style="background-color: rgb(52, 152, 219) !important"
              >
                <span class="c-icon">
                  <i class="fa fa-chevron-left"></i>
                </span>
                <span class="c-text">
                  <router-link
                    href="#"
                    aria-label="title"
                    :to="{
                      name: 'Leader-with-id-And-brnId-And-type',
                      params: {
                        type: leader.modID,
                        id: leader.postID,
                        BrnId: leader.brnID,
                        parentID: leader.parentID,
                      },
                    }"
                    >{{ leader.postTitle }}</router-link
                  >
                </span>
              </p>
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="course-details-content">
              <div class="single-course-details single-course-detail">
                <div class="row">
                  <div class="col-md-12">
                    <div class="single-item-text">
                      <h5 id="postTitle_"></h5>
                      <p id="postSubTitle_"></p>
                      <p id="postDetial_"></p>
                      <a
                        aria-label="title"
                        v-if="showdownload"
                        id="postFile_"
                        target="_blank"
                        ><i class="fa fa-download"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showAbout: true,
      showdownload: true,
      About: [],
      Leader: [],

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      BtnMore: "",
      Detials: "",
      ID: "",
      BrnID: "",
    };
  },
  methods: {
    getAboutById(id) {
      var self = this;
      self.ID = this.$route.params.id;
      self.BrnID = this.$route.params.BrnId;
      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("PostId", id);
      bodyFormData1.append("FbrnId", self.BrnID);
      bodyFormData1.append("Type", "Univ.Faculty.Activity.sub");
      bodyFormData1.append("ParentId", self.ID);
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          document.getElementById("postTitle_").innerText =
            response.data[0]["postTitle"];
          document.getElementById("postSubTitle_").innerText =
            response.data[0]["postSubTitle"];
          document.getElementById("postDetial_").innerHTML =
            response.data[0]["postDetails"];
          if (response.data[0]["postFile"] != 0) {
            self.showdownload = true;
            document
              .getElementById("postFile_")
              .setAttribute(
                "href",
                "https://api2.yuniv.net:444/images/post/" +
                  response.data[0]["postFile"]
              );
          } else {
            self.showdownload = false;
          }
        })
        .catch(function () {
          // console.log("error", response);
        });
    },
    getApis() {
      var self = this;
      self.ID = this.$route.params.id;
      self.BrnID = this.$route.params.BrnId;

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("PostId", self.ID);
      bodyFormData.append("FbrnId", self.BrnID);
      bodyFormData.append("Type", "Univ.Faculty.Activity");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          document.getElementById("deptTitle").innerText =
            response.data[0]["postTitle"];

          var bodyFormData = new FormData();
          bodyFormData.append("check", "getPostDetails");
          bodyFormData.append("Lang", localStorage.getItem("lang"));
          bodyFormData.append("PostId", "");
          bodyFormData.append("FbrnId", self.BrnID);
          bodyFormData.append("Type", "Univ.Faculty.Activity.sub");
          bodyFormData.append("ParentId", self.ID);
          bodyFormData.append("PostName", "");
          bodyFormData.append("Pno", "20");
          axios({
            method: "post",
            url: "https://api2.yuniv.net:444/our_team/getPost",
            data: bodyFormData,
          })
            .then(function (response) {
              self.About = response.data;

              if (self.About.length != 0) {
                self.getAboutById(response.data[0]["postID"]);
              }
              var bodyFormData = new FormData();

              bodyFormData.append("check", "getPost");
              bodyFormData.append("Lang", localStorage.getItem("lang"));
              bodyFormData.append("FbrnId", self.BrnID);
              bodyFormData.append("Type", "Univ.Dept.Leader");
              bodyFormData.append("ParentId", self.ID);
              bodyFormData.append("PostName", "");
              bodyFormData.append("Pno", "6");
              axios({
                method: "post",
                url: "https://api2.yuniv.net:444/our_team/getPost",
                data: bodyFormData,
              })
                .then(function (response) {
                  self.Leader = response.data;
                  if (self.About.length != 0 && self.Leader.length) {
                    self.showAbout = true;
                  } else {
                    self.showAbout = false;
                  }

                  document.getElementById("VueMainJsNewTouch")?.remove();
                  let recaptchaScript = document.createElement("script");
                  recaptchaScript.setAttribute("src", "/js/main.js");
                  recaptchaScript.id = "VueMainJsNewTouch";
                  document.head.appendChild(recaptchaScript);
                  setTimeout(() => {
                    self.show = "false";
                    document
                      .querySelectorAll("div.loader")
                      .forEach(function (element) {
                        element.setAttribute("hidden", true);
                      });
                  }, 100);
                })
                .catch(function () {
                  // console.log("error", response);
                });
            })
            .catch(function () {
              //   console.log("error", response);
            })
            .catch(function () {
              // console.log("error", response);
            });
        })

        .catch(function () {
          // console.log("error", response);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == "Ar") {
      self.translate = self.ArTranslat;
      document.getElementById("langfilewebsites")?.remove();
    } else {
      self.translate = self.EnTranslat;
      let link1 = document.createElement("link");
      link1.setAttribute("rel", "stylesheet");
      link1.id = "langfilewebsites";
      link1.setAttribute("href", "../../style-ltr.css");
      document.head.appendChild(link1);
    }
    self.CollegeNews = self.translate[0]["Home"]["CollegeNews"];
    self.CollegeDept = self.translate[0]["Home"]["CollegeDept"];
    self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
    self.PhotoBrowser = self.translate[0]["Home"]["PhotoBrowser"];
    self.Detials = self.translate[0]["Home"]["Detials"];
    self.getApis();
  },
};
</script>
<style scoped>
.newsletter-area {
  background: #0c3ef7 none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}

.c-text a {
  cursor: pointer;
}
.ImagesFID {
  width: 100% !important;
}
.about-area h3 {
  font-size: 30px;
  margin-bottom: 79px;
  padding-top: 0px;
  z-index: 9;
  position: relative;
}
.about-area:before {
  height: 79px;
  right: 0;
  left: auto;
  top: -32px;
  width: 49.2%;
}
.contact-text p {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 10px;
}
.contact-text p span a {
  color: #fff;
}
.single-item-text {
  padding: 15px;
  height: auto;
}
.course-details-content {
  margin: 0;
}
.single-item-text h5 {
  font-weight: bold;
}
.single-item-text p {
  margin-top: 10px;
  text-align: justify;
  line-height: 30px;
}
.single-course-details {
  padding: 10px 0 !important;
  margin-bottom: 0;
  box-shadow: -1px 8px 15px 5px #ccc;
}
.about-container h3 {
  color: #000;
}
.header-logo-menu.stick.semi-transparent,
.gallery-img,
.gallery-img .hover-effect,
.single-teacher-image > a:after {
  background: rgb(52 152 219 / 63%) none repeat scroll 0 0;
}
#gelery {
  padding: 10px;
}
#groupImages {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 30px;
}
.btn-warning {
  text-align: center;
  margin-top: 30px;
}
</style>
